(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('InvoiceApprovalController', InvoiceApprovalController);

    InvoiceApprovalController.$inject = ['$scope', '$http', '$window', 'AlertService'];

    function InvoiceApprovalController ($scope, $http, $window, AlertService) {
        var vm = this;

        vm.accountEntriesValidatedOk = false;
        vm.vismaVoucherSuccessful = {
            projectName: null,
            voucherNumber: null
        };

        // Find first and last date of previous month.
        var periodStartDate = moment()
            .subtract(1,'months')
            .startOf('month');
        var periodEndDate = moment()
            .subtract(1,'months')
            .endOf('month');

        $('#periodStartDateDropdown').datetimepicker({
            format: 'DD.MM.YYYY',
            locale: 'nb',
            defaultDate: periodStartDate
        });

        $('#periodEndDateDropdown').datetimepicker({
            format: 'DD.MM.YYYY',
            locale: 'nb',
            defaultDate: periodEndDate
        });

        loadData(periodStartDate, periodEndDate);

        $scope.setPeriodStartDate = function () {
            $("#periodStartDateDropdown").datetimepicker().on("dp.change", function (data) {
                periodStartDate = data.date;
                loadData(data.date, periodEndDate);
            });
        };

        $scope.setPeriodEndDate = function () {
            $("#periodEndDateDropdown").datetimepicker().on("dp.change", function (data) {
                periodEndDate = data.date;
                loadData(periodStartDate, data.date);
            });
        };

        $scope.isCollapsed = function (index) {
            // TODO: Improve this.
            var cAttribute = $("#collapse" + (index + 1)).attr("class");
            if (cAttribute) {
                return cAttribute.indexOf('in') >= 0;
            }

            return null;
        };

        $scope.loadProjectDetails = function (index, project) {
            // Only get accounting details when opening the collapsable panel.
            if (!$scope.isCollapsed(index)) {
                $http.get('/api/project/invoice/accounting/'
                        + project.projectId + '/'
                        + project.periodFrom + '/'
                        + project.periodTo).then(function (result) {
                    project.accounting = result.data;
                }, function (result) {
                    console.log(result);
                    alert('Klarte ikke laste posteringer for prosjektet. Prøv igjen og gi beskjed til din ' +
                        'kontaktperson i DevelopIT dersom problemet vedvarer.');
                });
            }
        };

        $scope.validateAccountingEntries = function (projectId) {
            $http.get('/api/project/invoice/accounting/validate/'
                + projectId + '/'
                + periodStartDate.format("YYYY-MM-DD") + '/'
                + periodEndDate.format("YYYY-MM-DD")).then(function (result) {
                    if (result.data.errorFlag === 'OK') {
                        $('#showAccountEntriesValidatedOkModal').modal('show');
                        vm.accountEntriesValidatedOk = true;
                    } else {
                        alert('Validering feilet. Dette må undersøkes før det vil være mulig å sende til Visma.');
                    }
            }, function (result) {
                console.log(result);
                alert('Klarte ikke validere posteringer. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        };

        $scope.createInvoiceReport = function (projectId) {
            var url = null;
            var from = periodStartDate.format("YYYY-MM-DD");
            var to = periodEndDate.format("YYYY-MM-DD");
            var win = $window.open();
            $http({
                method: 'GET',
                url: 'api/invoice/' + from + '/' + to + '/' + projectId
                    + '?includeMass=false'
                    + '&reportOnAnsvarAndTjeneste=true'
                    + '&preview=true'
                    + '&includeProduct=false'
                    + '&includeInactiveEquipment=false'
                    + '&includeMissingFromPreviousInvoices=false'
                    + '&invoiceForExternal=false'
                    + '&invoiceableStatus=ALL'
                    + '&subProjectCodes=', // TODO: Probably need to support sub projects for Famac!
                data: null
            }).then(function(response) {
                url = response.data.url;
                win.location = url;
            }, function (response)  {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.sendAccountEntriesToVisma = function (projectId, projectName) {
            $http.get('/api/project/invoice/accounting/submit/'
                + projectId + '/'
                + periodStartDate.format("YYYY-MM-DD") + '/'
                + periodEndDate.format("YYYY-MM-DD")).then(function (result) {
                if (result.data.errorFlag === 'OK') {
                    vm.vismaVoucherSuccessful.voucherNumber = result.data.voucherNumber;
                    vm.vismaVoucherSuccessful.projectName = projectName;
                    $('#showAccountEntriesSentModal').modal('show');
                    loadData(periodStartDate, periodEndDate);
                } else {
                    alert('En eller flere posteringer feilet. Dette skal ikke skje. Gi beskjed til din kontaktperson ' +
                        'i DevelopIT AS dersom du får denne feilmeldingen.');
                }
            }, function (result) {
                console.log(result);
                alert('Klarte ikke sende posteringer til Visma. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        };

        function loadData(periodStartDate, periodEndDate) {
            $http.get('/api/project/invoice/approval/'
                    + periodStartDate.format("YYYY-MM-DD") + '/'
                    + periodEndDate.format("YYYY-MM-DD")).then(function (result) {
                vm.approvals = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste fakturagodkjenning. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT AS dersom problemet vedvarer.');
            });
        }
    }
})();
